import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import DietaMenus from "../Dieta/DietaMenusSelect";
import CrudTable from "components/Table/CrudTable/CrudTable";
import {getNutitionistId} from "api/loader";
import { MENU_SELECT_LIST, NUTRITIONIST } from "store/constants";
import AppView from "components/AppView/AppView";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal/Modal";
import ComposicionAlimentos from "pages/Configuracion/Nutriologos/ComposicionAlimentos";
import { Button } from "@mui/material";
import AddAlimentoMenuSelect from "./AddAlimentoMenuSelect";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell"
import MapCell from "components/Table/CellTypes/MapCell/MapCell"
import NewButton from "components/Buttons/NewButton/NewButton";

import {tiemposComidaDietasAutomaticas, tiposComidaDietasAutomaticas, tagsDietasAutomaticas} from "constants/ai";
import FileCell from "components/Table/CellTypes/FileCell/FileCell";
const TabDietas = () => {
	const nutId = getNutitionistId();
	const dispatch = useDispatch();
	const {t} = useTranslation("common");
	const data = useSelector(state => state[MENU_SELECT_LIST.name]);
	const dataNutritionist = useSelector(state => state[NUTRITIONIST.name].data.find(n=>n._id===nutId));
	const [selectedData, setSelectedData] = useState();
	const [, setShowModalAsignacion] = useState(false);
	const [showModal, setShowModal] = useState(false);
	
	// const onClickEdit = (data) => {
	// 	setSelectedData(data);
	// 	setShowModal(true);
	// };

	const close = () => {
		setShowModalAsignacion(false);
	};

	// React.useEffect(() => {
	// 	if(dataNutritionist && !dataNutritionist?.tab_comp_alim) setShowModalAsignacion(true);
	// }, [dataNutritionist]);
	
	

	const columns = React.useMemo(
		() => [
			{
				Header: "Imagen",
				accessor: "imagen",
				Cell:FileCell
			},
			{
				Header: "Nombre",
				accessor: "nombre"
			},
			{
				Header: "Preparado",
				accessor: "preparado",
				Cell:BoolCell
			},
			{
				Header: "Tiempo de comida",
				accessor: "tiempos_c",
				Cell:MapCell,
				options:tiemposComidaDietasAutomaticas
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			
			{	
				Header: t("nombre"),
				accessor: "nombre",
			},
			{
				Header: "Preparado",
				accessor: "preparado",
				filter:"boolean",
				filterOptions:[{label:"Todos", value:""},{label:"Si", value:true},{label:"No",value:false}]
			},
			{
				Header: "Tiempo de comida",
				accessor: "tiempos_c",
				multiple:true,
				filter:"select",
				filterOptions:[{label:"Todos", value:""},...tiemposComidaDietasAutomaticas]
			},
			{
				Header: "Tipos de comida",
				accessor: "tipos_c",
				multiple:true,
				filter:"select",
				filterOptions:[{label:"Todos", value:""},...tiposComidaDietasAutomaticas]
			},
			{
				Header: "Tags",
				accessor: "tags",
				multiple:true,
				filter:"select",
				filterOptions:[{label:"Todos", value:""},...tagsDietasAutomaticas]
			},
		],
		[]
	);
	return (
		<>
			{(dataNutritionist && !dataNutritionist?.tab_comp_alim) ? <Composicion close={close} idNutritionist={nutId} />
				: 
				<>
					<div style={{display:"flex", gap:5, flexDirection:"row-reverse"}}>
						<NewButton options={{alimentoPreparado:true}} modal={AddAlimentoMenuSelect} fullWidth={false} type={MENU_SELECT_LIST} label="alimento preparado" />
						
						<NewButton options={{alimentoPreparado:false}} modal={AddAlimentoMenuSelect} fullWidth={false} type={MENU_SELECT_LIST} />
					
					</div>
					<CrudTable
						data={data.data}
						status={data.view}
						columns={columns}
						type={MENU_SELECT_LIST}
						modal={AddAlimentoMenuSelect}
						filters={filters}
						options={{ orderBy: true, saveAs: true, saveAsNameKey:"nombre", loadBeforeEdit:true, disableInsert:true}}
						fullWidth
						// editAction={onClickEdit}
						// insertAction={onClickEdit}
					/>
			
					{/* <Modal onClickClose={()=>setShowModal(false)} open={showModal} widthPaper="50%"><ListaAlimentos handleModal={()=>setShowModal(false)} data={selectedData} saveAs={true}/></Modal> */}
				</>
			}
		</>
	);
};

export default TabDietas;

const Composicion = ({ close, idNutritionist}) => (
	<>
		<ComposicionAlimentos idNutritionist={idNutritionist} />
		<div style={{textAlign: "center", marginTop: 10}}>
			<Button variant="contained" color="secondary" onClick={close}>Guardar</Button> 
		</div>
	</>
);